<template>
  <BackofficeBase :loader_prop="loader_prop">
    <DistributionPointMenu :point_id_prod="point_id_prod" />
    <div class="create_user_div">
    <router-link :to="{path : '/backoffice/backoffice_add_enduser', query : {'id' : $route.query.id}}">הוסף לקוח קצה</router-link>

    </div>
    <div v-for="user in data" :key="user.id" class="item_list">
      <div>
        <p>{{ user.name }}</p>
        <p>{{ user.phone_number }}</p>
        <p>{{ user.email }}</p>
      </div>
      <div class="item_action">
        <router-link
          :to="{ path: '/backoffice/backoffice_user_packages_admin/', query: { phone_number: user.phone_number } }"
          >חבילות</router-link
        >
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu";

export default {
  name: "BackofficeEndusers",
  components: {
    BackofficeBase,
    DistributionPointMenu
  },
  data() {
    return {
      loader_prop: true,
      data: [],
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    this.id = this.$route.query.id;
    this.point_id_prod = this.$route.query.id;
    let data = await backendModel.backendRequest(
      "/Api/service/backoffice/backoffice_get_point_endusers",
      { id: this.id }
    );
    this.data = data.data.data;
    console.log(this.data);
    this.loader_prop = false;
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>
